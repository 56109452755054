.aptfy-checkbox {
  align-items: flex-start;
  background-color: #f1f1f1;
  border-radius: var(--border-radius);
  display: flex;
  padding: var(--step-md) var(--step-sm);
}

.aptfy-checkbox_input {
  height: 18px;
  margin-left: var(--step-xs);
  margin-right: var(--step-md);
  width: 18px;
}

.aptfy-checkbox_body {
  flex: 1;
}
.aptfy-checkbox_title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: var(--step-xs);
}
.aptfy-checkbox_description {
  font-size: 13.33px;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
}
