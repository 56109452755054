:root {
  --opacity-high-emphasis: 0.87;
  --opacity-md-emphasis  : 0.60;
  --opacity-disabled     : 0.38;
  --opacity-border       : 0.12;

  --color-wheat: #f2b366;
  --color-ubi: #5741a6;
  --color-royal-purple: #1e2430;

  --color-bg    : #fafafa;
  --color-text  : rgba(0, 0, 0, var(--opacity-high-emphasis));
  --color-border: rgba(0, 0, 0, var(--opacity-border));

  --step-xs: 3.5px;
  --step-sm: 7px;
  --step-md: 14px;
  --step-lg: 21px;
  --step-xl: 34px;

  --border-radius: 5px;
  --border-radius-extra: 9px;

  --main-bg-color: rgb(250, 250, 250);
  --main-text-color: rgba(0, 0, 0, var(--opacity-high-emphasis));
  --main-border-color: rgba(0, 0, 0, 0.12);
  --main-border-color__hardcoded: rgb(220, 220, 220);
  --primary-color: rgb(68, 116, 157);
  --primary-color-alt-green: rgb(68, 157, 109);
  --primary-color-alt-red: rgb(157, 68, 92);
  --primary-color-highlight: rgba(68, 116, 157, 0.12);
  --primary-color-light: rgba(68, 116, 157, var(--opacity-high-emphasis));
  --primary-color-lighter: rgba(68, 116, 157, 0.54);
  --secondary-color: rgb(158, 158, 158);
  --divider: rgba(0, 0, 0, var(--opacity-high-emphasis));
}

body {
  word-break: break-word;
}
body,
input,
textarea,
select {
  color: var(--main-text-color);
  font-family: system-ui;
}

#root {
  display: flex;
  flex-direction: row;
}

main {
  flex: 1 1;
  overflow: auto;
}

[hidden] {
  display: none !important;
}

button, button *, a, a * {
  cursor: pointer !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}

.flex-1  { flex: 1;  }
.flex-2  { flex: 2;  }
.flex-3  { flex: 3;  }
.flex-4  { flex: 4;  }
.flex-5  { flex: 5;  }
.flex-6  { flex: 6;  }
.flex-7  { flex: 7;  }
.flex-8  { flex: 8;  }
.flex-9  { flex: 9;  }
.flex-10 { flex: 10; }

.align-top    { align-items: flex-start; }
.align-center { align-items: center;     }
.align-bottom { align-items: flex-end;   }

.justify-top    { justify-content: flex-start; }
.justify-center { justify-content: center;     }
.justify-bottom { justify-content: flex-end;   }

.text-align-left   { text-align: left;   }
.text-align-right  { text-align: right;  }
.text-align-center { text-align: center; }

.text-capitalize {
  text-transform: capitalize;
}
.text-secondary {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
}
.text-caption {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  line-height: 1.25rem;
}
.text-overline {
  font-size: 0.78em;
  letter-spacing: 0.14em;
  text-transform: uppercase;
}

.fill-window {
  height: 100vh;
  width: 100vw;
}

.top-spacer-none { margin-top: 0              !important; }
.top-spacer-xs   { margin-top: var(--step-xs) !important; }
.top-spacer-sm   { margin-top: var(--step-sm) !important; }
.top-spacer-md   { margin-top: var(--step-md) !important; }
.top-spacer-lg   { margin-top: var(--step-lg) !important; }
.top-spacer-xl   { margin-top: var(--step-xl) !important; }

.bottom-spacer-none { margin-bottom: 0              !important; }
.bottom-spacer-xs   { margin-bottom: var(--step-xs) !important; }
.bottom-spacer-sm   { margin-bottom: var(--step-sm) !important; }
.bottom-spacer-md   { margin-bottom: var(--step-md) !important; }
.bottom-spacer-lg   { margin-bottom: var(--step-lg) !important; }
.bottom-spacer-xl   { margin-bottom: var(--step-xl) !important; }

.left-spacer-xs { margin-left: var(--step-xs) !important; }
.left-spacer-sm { margin-left: var(--step-sm) !important; }
.left-spacer-md { margin-left: var(--step-md) !important; }
.left-spacer-lg { margin-left: var(--step-lg) !important; }

.right-spacer-xs { margin-right: var(--step-xs) !important; }
.right-spacer-sm { margin-right: var(--step-sm) !important; }
.right-spacer-md { margin-right: var(--step-md) !important; }
.right-spacer-lg { margin-right: var(--step-lg) !important; }

.wrapable-form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7px;

  .text-field {
    margin-left: var(--step-sm);
    margin-top: var(--step-sm);
  }
}

.emphasis-high { color: rgba(0,0,0,var(--opacity-high-emphasis)); }
.emphasis-md   { color: rgba(0,0,0,var(--opacity-md-emphasis)); }
.disabled      { color: rgba(0,0,0,var(--opacity-disabled)); }

.center-on-wide-screens {
  box-sizing: border-box;
  margin: 0px auto;
  max-width: 640px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
