.login-wall {
  display: flex;

  .section-info {
    background-color: var(--color-wheat);
    display: none;
    flex: 16;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    @media (min-width: 693px) { display: flex; }

    h1 {
      color: var(--color-ubi);
      display: flex;
      flex: 1;
      flex-direction: column;
      font-size: 3.2360em;
      justify-content: center;
      padding: 32px 26px;
      small {
        font-weight: normal;
      }
    }

    table {
      border-spacing: 0px;
      color: var(--color-royal-purple);
      font-family: monospace;
      font-size: 1.6180em;
      margin-bottom: 100px;
    }
    tr:nth-child(odd) td {
      background-color: rgba(0,0,0,.072);
    }
    td {
      padding: 11px 17px;
    }
  }

  .section-action {
    background-color: var(--color-royal-purple);
    color: white;
    display: flex;
    flex-direction: column;
    flex: 10;
    padding: 28px 32px;

    h1 {
      font-size: 1.6180em;
      letter-spacing: 0.049em;
      margin: 0;
      @media (min-width: 693px) { display: none; }
    }

    .callout-login {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 48px;
      margin-top: 32px;

      h2 {
        color: white;
        font-size: 1.3820em;
        margin-bottom: .55em;
        margin-top: 0;
        text-align: center;
        @media (min-width: 693px) { font-size: 2em; }
      }
    }
    .btn-group-login {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: -5px;
      .btn-login { margin: 5px; }
    }

    footer {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: fit-content;

      img {
        height: 2em;
        width: auto;
      }
      a {
        color: white;
        padding: 6px;
        text-decoration: none;
        text-align: center;
      }
    }
  }
}
