.widget-issue-toolbar {
  display: flex;
  padding: 6px;

  button {
    border-radius: 6px;
    padding: 6px 9px;
    font-size: 24px;
    &:not(:first-of-type) { margin-left: 3px; }
  }
}
