.page-building .container-member-cards .card-member {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: var(--border-radius-extra);
  box-shadow: var(--box-shadow);
  position: relative;
  width: 172px;

  img {
    border-top-left-radius: var(--border-radius-extra);
    border-top-right-radius: var(--border-radius-extra);
  }
  .role {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.615);
    border-radius: 16px;
    color: rgba(0, 0, 0, 0.96);
    font-size: 13px;
    font-weight: 400;
    justify-content: center;
    left: 50%;
    letter-spacing: 0.02em;
    padding: 2px 7.5px 3px;
    position: absolute;
    text-align: center;
    top: 142px;
  }

  .body {
    padding: 7px 9px;
  }

  .info {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
  .name {
    font-weight: 500;
  }
}
