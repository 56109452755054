.widget-statement {
  background-color: white;
  border-collapse: collapse;
  border-radius: 10px;
  border-spacing: 0px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;

  th {
    padding: 16px 12px;
  }
  td {
    max-width: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .row-item,
  .row-toggle {
    border-top: 1px solid #e0e0e0;
    cursor: pointer;
    &:hover { background-color: rgba(0, 0, 0, 0.08); }
  }

  .row-item td {
    padding: 6px 12px;
  }

  .row-toggle {
    text-align: center;
    td {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      padding: 12px 12px 11px;
    }
  }

  .cell-item-details {
    padding: 8px 12px 16px;
  }
  .cell-no-transactions {
    border-top: 1px solid #e0e0e0;
    color: rgba(0, 0, 0, var(--opacity-disabled));
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.0178571429em;
    padding: 24px 16px;
    text-align: center;
  }

  .transaction-date {
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.0178571429em;
  }
  .transaction-type {
    font-weight: 500;
  }
  .payment-indicator {
    font-size: 19px;
    line-height: 0px;
    margin-right: 7px;
    position: relative;
    top: 4px;
    @media (max-width: 719.9px) { display: none; }
  }
  .reimbursement-indicator {
    line-height: 0px;
    margin-right: 13px;
    position: relative;
    top: 6px;
    @media (max-width: 719.9px) { display: none; }
  }
}

.widget-rent,
.widget-balance {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  padding: 11px;
  width: fit-content;
  h3 {
    color: rgba(0, 0, 0, 0.618);
    font-size: 0.9rem;
    margin-bottom: 2px;
    font-weight: 400;
    letter-spacing: 0.0333333333em;
    line-height: 1.25rem;
    margin: 0px 0px 2px;
  }
  .value {
    font-size: 22px;
  }
  p {
    font-size: 0.9rem;
    margin: 1px 0px 0px 1px;
  }
}
