.portal-selector {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.portal-option_tenant,
.portal-option_management {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.portal-option_tenant {
  background-color: var(--color-royal-purple);
  color: white;
}
.portal-option_management {
  background-color: #fafafa;
  color: var(--main-text-color);
}

.portal-option_tenant h2,
.portal-option_management h2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: auto;
  max-width: 242px;
  min-height: 346px;
  padding: 14px 14px 150px;
  width: 100%;
}
