.page-tenant-start-guide {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  padding: 16px 16px 100px;
  width: 100%;

  > h1:first-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    img {
      border-radius: 50%;
      margin-bottom: 5px;
    }
    small {
      color: rgba(0, 0, 0, 0.625);
      font-size: large;
    }
  }

  > h2 {
    color: rgba(0, 0, 0, 0.76);
    font-size: large;
    margin-top: 48px;
    text-align: center;
  }

  > p {
    color: rgba(0, 0, 0, 0.625);
    line-height: 1.4535;
    text-align: center;
  }

  .toolbar {
    display: flex;
    > * {
      flex: 1;
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
    .btn-icon-over-text {
      min-width: unset;
    }
  }

  .widget-form {
    box-sizing: border-box;
    margin-top: 8px;
    width: 100%;
  }
}
