.page-issues {
  padding: 14px 14px 100px;

  .toolbar {
    display: flex;
    justify-content: center;
    button, a {
      flex-basis: 160px;
      &:not(:first-child) { margin-left: 7px; }
      @media (max-width: 681px) { flex-grow: 1; } // Till becomes two columns
    }
    .btn-icon-over-text {
      min-width: unset;
    }
  }

  .widget-form {
    box-sizing: border-box;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    max-width: 654px;
    width: 100%;
  }

  .issues {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    justify-content: center;
    margin-top: 14px;

    .widget-issue {
      flex-basis: 320px;
      @media (max-width: 681px) { flex-grow: 1; } // Till becomes two columns
    }

    > p {
      color: rgba(0, 0, 0, 0.625);
      margin-top: 3em;
      margin-bottom: 3em;
      text-align: center;
    }
  }
}
