.widget-form-switch-tenancy {
  
  > div:last-child {
    margin-top: 11px;
  }

  a {
    padding: 11px;
    margin-bottom: 5.5px;
    letter-spacing: 0.07em !important; // Override btn
    text-align: left !important; // Override btn
    text-transform: unset !important; // Override btn
    i { margin-right: 9px; }
    .tenants {
      margin-top: 2px;
      > :not(:last-child)::after { content: ', '; }
    }
  }
}
