.widget-issue-form {
  padding: 16px;

  p {
    color: rgba(0, 0, 0, 0.625);
  }

  > div:last-child {
    margin-top: 6px;
  }
}
