.widget-form-user-profile {
  img {
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .login-email {
    margin-top: 3.5px;
    text-align: center;
  }
}
