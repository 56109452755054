.page-file-room {
  padding: 14px 14px 100px;

  .toolbar {
    display: flex;
    justify-content: center;
    button, a {
      flex-basis: 158.25px;
      &:not(:first-child) { margin-left: 7px; }
      @media (max-width: 681px) { flex-grow: 1; } // Till becomes two columns
    }
    .btn-icon-over-text {
      min-width: unset;
    }
  }

  .widget-form {
    box-sizing: border-box;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    max-width: 654px;
    width: 100%;
  }

  .buildings {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    justify-content: center;
    margin-top: 14px;

    > header,
    > div {
      background-color: rgba(255, 255, 255, 0.625);
      border-radius: 10px;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
      box-sizing: border-box;
      flex-basis: 320px;
      @media (max-width: 681px) { flex-grow: 1; } // Till becomes two columns
    }

    > header {
      padding-bottom: 14px;
      padding-left: 21px;
      padding-right: 21px;
      h1 {
        color: rgba(0, 0, 0, 0.87);
        small {
          align-items: center;
          color: rgba(0, 0, 0, 0.625);
          display: flex;
          margin-top: 3.5px;
          &:first-child { margin-top: 7px; }
          i { margin-right: 7px; }
          .icon-building-count { position: relative; top: 1px;   }
          .icon-unit-count     { position: relative;             }
          .icon-total-rent     { position: relative; top: 0.5px; }
          .icon-unpaid         { position: relative; top: 1.5px; }
          .icon-everyone-paid  { position: relative; top: 1.5px; }
        }
      }
      p {
        color: rgba(0, 0, 0, 0.625);
        font-size: 0.92rem;
        font-weight: 400;
        letter-spacing: 0.0178571429em;
        line-height: 1.25rem;
      }
      button {
        display: block;
        margin: auto;
      }
    }

    .street-view {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: auto;
      width: 100%;
    }

    .building-creator {
      display: flex;
      flex-direction: column;
      form {
        padding: 7px;
      }
      .input-num-of-units {
        margin-left: auto;
        width: 175px;
      }
      .actions {
        margin-top: 11px;
        margin-bottom: 11px;
      }
      .units {
        display: flex;
        flex-wrap: wrap;
        margin: -2.5px;
        > div {
          box-sizing: border-box;
          padding: 2.5px;
          width: 33.333%;
          > div {
            padding: 2.5px 2.5px 3.5px;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.12);
          }
        }
      }
    }

    .building {
      display: flex;
      flex-direction: column;
      .building-address {
        display: block;
        font-size: medium;
        font-weight: bold;
        line-height: 1.225;
        margin: 9px;
        padding: 6px 12px 9px;
        &::first-line { font-size: x-large; }
      }
      .building-financials {
        align-items: center;
        display: flex;
        font-family: monospace;
        font-size: medium;
        font-weight: bold;
        margin: 2px 15px 11px 53.5px; // Right margin is width of: unit left margin + unit button + margin + tenancy padding, resulting in alignment with tenancy financials.
        i {
          font-size: 16px;
          margin-right: 3.5px;
        }
      }
    }

    .unit {
      display: flex;
      margin-left: 9px;
      margin-right: 9px;
      padding-top: 9px;
      padding-bottom: 9px;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
      &.expanded {
        min-height: 109.5px;
      }
      .btn-unit-designator {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        font-family: system-ui;
        font-size: 14px;
        font-weight: bold;
        height: 52px; // Height of tenancy with one line of names. Button height also serves as min unit height.
        justify-content: center;
        line-height: 1.25;
        margin-right: 6px;
        padding: 6px;
        width: 52px; // Match height to square up button.
      }
      .tenancies {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
      .btn-add-tenancy {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        height: 52px;
        justify-content: center;
        &:not(:first-child) { margin-top: 6px; }
      }
    }

    .tenancy {
      box-sizing: border-box;
      min-height: 52px;
      padding: 7px 9px;
      &:not(:first-child) {
        margin-top: 7px;
      }
      .tenancy-tenants {
        min-height: 19px; // Prevents layout shift on load name
        > :not(:last-child)::after { content: ', '; }
      }
      .tenancy-financials, .tenancy-commencement-date {
        margin-top: 3.5px;
      }
      .tenancy-financials {
        font-family: monospace;
        font-weight: bold;
      }
      .tenancy-balance {
        color: green;
        &.owes-money { color: red; }
      }
    }
  }
}
