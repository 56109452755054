.btn-login {
  align-items: center;
  border: none;
  border-radius: var(--border-radius-extra);
  display: flex;
  font-size: 17px;
  letter-spacing: 0.042em;
  width: 250px;
}

.btn-login-apple {
  background-color: black;
  color: white;
  justify-content: center;
  padding: 12.25px;

  img {
    margin-right: 8px;
  }

  &:hover  { box-shadow: 0 0 8px  rgba(255, 255, 255, 0.3); }
  &:focus  { box-shadow: 0 0 12px rgba(255, 255, 255, 0.5); }
  &:active { box-shadow: 0 0 5px  rgba(255, 255, 255, 0.1); }
}

.btn-login-google {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  padding: 3px;

  img {
    margin-right: 20px;
  }

  &:hover  { box-shadow: 0 0 8px  rgba(255, 255, 255, 0.6); }
  &:focus  { box-shadow: 0 0 12px rgba(255, 255, 255, 0.8); }
  &:active { box-shadow: 0 0 5px  rgba(255, 255, 255, 0.4); }
}
