.btn-primary,
.btn-filled-primary,
.btn-alt,
.btn-filled-alt,
.btn-change,
.btn-filled-change,
.btn-remove,
.btn-filled-remove {
  /* There are four styles of buttons each having a filled and transparent
   * version. "Primary" for buttons that move the navigation forward, "alt" for
   * buttons that perform some kind of side task, "change" for buttons that
   * change the data, and "remove" for buttons that remove data.
   * Orginal colors from https://designerly.com/best-button-colors-for-websites */
  --btn-color-base-primary: rgb( 87, 131, 219);
  --btn-color-base-alt    : rgb(128, 102, 157);
  --btn-color-base-change : rgb( 93, 190, 163);
  --btn-color-base-remove : rgb(221, 121, 115);

  /* Text colors for transparent buttons. */
  --btn-transparent-text-color-primary: rgb(  44,  98, 206); // 18% darker than base
  --btn-transparent-text-color-alt    : rgb( 128, 102, 157); //  0% darker than base
  --btn-transparent-text-color-change : rgb(  50, 125, 104); // 38% darker than base
  --btn-transparent-text-color-remove : rgb( 209,  74,  66); // 18% darker than base

  /* Colors for buttons with a filled background. */
  --btn-filled-text-color      : white;
  --btn-filled-bg-color-primary: var(--btn-color-base-primary);
  --btn-filled-bg-color-alt    : var(--btn-color-base-alt);
  --btn-filled-bg-color-change : var(--btn-color-base-change);
  --btn-filled-bg-color-remove : var(--btn-color-base-remove);

  /* Background colors for buttons with a filled background adjusted for hover,
   * focus, and active effects. Composed of corresponding base colors plus alpha. */
  --btn-filled-bg-color-primary-hover : rgba( 87, 131, 219, 0.86);
  --btn-filled-bg-color-primary-focus : rgba( 87, 131, 219, 0.82);
  --btn-filled-bg-color-primary-active: rgba( 87, 131, 219, 0.78);
  --btn-filled-bg-color-alt-hover     : rgba(128, 102, 157, 0.86);
  --btn-filled-bg-color-alt-focus     : rgba(128, 102, 157, 0.82);
  --btn-filled-bg-color-alt-active    : rgba(128, 102, 157, 0.78);
  --btn-filled-bg-color-change-hover  : rgba( 93, 190, 163, 0.86);
  --btn-filled-bg-color-change-focus  : rgba( 93, 190, 163, 0.82);
  --btn-filled-bg-color-change-active : rgba( 93, 190, 163, 0.78);
  --btn-filled-bg-color-remove-hover  : rgba(221, 121, 115, 0.86);
  --btn-filled-bg-color-remove-focus  : rgba(221, 121, 115, 0.82);
  --btn-filled-bg-color-remove-active : rgba(221, 121, 115, 0.78);

  border: none;
  border-radius: var(--border-radius-extra);
  font-size: 13px;
  font-weight: normal; // Necessary to override user agent stylesheet
  letter-spacing: 0.0875em;
  margin: 0px; // Necessary to override user agent stylesheet for <a> on Safari
  padding: 13px;
  text-transform: uppercase;
}
.btn-unstyled {
  border: none;
  border-radius: var(--border-radius-extra);
}

a.btn-primary,
a.btn-filled-primary,
a.btn-alt,
a.btn-filled-alt,
a.btn-change,
a.btn-filled-change,
a.btn-remove,
a.btn-filled-remove {
  text-align: center;
  text-decoration: none;
}
a.btn-unstyled {
  text-decoration: none;
}

/* Applies color and effects to transparent buttons. */
.btn-primary,
.btn-alt,
.btn-change,
.btn-remove,
.btn-unstyled {
  background: transparent;
  &:hover:not([disabled])  { background-color: rgba(1, 1, 1, 0.08); }
  &:focus:not([disabled])  { background-color: rgba(1, 1, 1, 0.12); }
  &:active:not([disabled]) { background-color: rgba(1, 1, 1, 0.16); }
}
.btn-primary  { color: var(--btn-transparent-text-color-primary);   }
.btn-alt      { color: var(--btn-transparent-text-color-alt);       }
.btn-change   { color: var(--btn-transparent-text-color-change);    }
.btn-remove   { color: var(--btn-transparent-text-color-remove);    }
.btn-unstyled { color: rgba(0, 0, 0, var(--opacity-high-emphasis)); }

/* Applies color and effects to filled buttons. */
.btn-filled-primary,
.btn-filled-alt,
.btn-filled-change,
.btn-filled-remove {
  color: var(--btn-filled-text-color );
}
.btn-filled-primary {
  background-color: var(--btn-filled-bg-color-primary);
  &:hover:not([disabled])  { background-color: var(--btn-filled-bg-color-primary-hover);  }
  &:focus:not([disabled])  { background-color: var(--btn-filled-bg-color-primary-focus);  }
  &:active:not([disabled]) { background-color: var(--btn-filled-bg-color-primary-active); }
}
.btn-filled-alt {
  background-color: var(--btn-filled-bg-color-alt);
  &:hover:not([disabled])  { background-color: var(--btn-filled-bg-color-alt-hover);  }
  &:focus:not([disabled])  { background-color: var(--btn-filled-bg-color-alt-focus);  }
  &:active:not([disabled]) { background-color: var(--btn-filled-bg-color-alt-active); }
}
.btn-filled-change {
  background-color: var(--btn-filled-bg-color-change);
  &:hover:not([disabled])  { background-color: var(--btn-filled-bg-color-change-hover);  }
  &:focus:not([disabled])  { background-color: var(--btn-filled-bg-color-change-focus);  }
  &:active:not([disabled]) { background-color: var(--btn-filled-bg-color-change-active); }
}
.btn-filled-remove {
  background-color: var(--btn-filled-bg-color-remove);
  &:hover:not([disabled])  { background-color: var(--btn-filled-bg-color-remove-hover);  }
  &:focus:not([disabled])  { background-color: var(--btn-filled-bg-color-remove-focus);  }
  &:active:not([disabled]) { background-color: var(--btn-filled-bg-color-remove-active); }
}

.btn-icon-over-text {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 112px;
  padding: 5px 11px;
  text-transform: none;
  span {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: normal;
    margin-top: 2px;
    white-space: nowrap;
  }
}

.btn-background-img {
  position: relative;
  z-index: 1;
}
.btn-background-img::before {
  background-image: var(--btn-background-image);
  background-position: center 33%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9px;
  content: "";
  opacity: 0.375;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
