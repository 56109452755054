.text-field {
  background-color: #f1f1f1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  position: relative;

  .label {
    --label-height: 24px;
    color: rgba(0,0,0,.6);
    font-size: 12px;
    letter-spacing: -0.35px;
    padding: 8px 10px 2px;
    box-sizing: border-box;
    height: var(--label-height);
    min-height: var(--label-height);
    max-height: var(--label-height);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
  }

  .asterisk {
    bottom: 3px;
    color: #f44336;
    font-size: 10px;
    margin-left: 5px;
    position: relative;
  }

  textarea,
  input {
    all: unset;
    border-bottom: 1px solid rgba(0,0,0,.42);
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    font-size: 16px;
    padding: 10px 10px 5px; // Padding without label.

    &:not(:read-only) {
      cursor: text;

      &:focus {
        border-bottom-width: 2px;
        margin-bottom: -1px; // Prevents items below from being nudged down upon border width increase.
      }
    }
  }

  .label~textarea,
  .label~input {
    padding-top: 2px; // Padding with label. Reduced to acommodate for label above.
  }

  input {
    &[type=month],
    &[type=date],
    &[type=datetime-local] {
      height: 21px; // Must be set explicitly for date fields otherwise browser will grossly oversize.
      padding-bottom: 3px; // Reduced bottom padding to acommodate for additional height.
    }
    &[readonly][value=""]::-webkit-datetime-edit {
      visibility: hidden; // Hides date placeholder/format when read only.
    }
    &::-webkit-calendar-picker-indicator {
      border-radius: var(--border-radius);
      bottom: 6px;
      cursor: pointer;
      font-size: 15px;
      opacity: .54;
      padding: 5px;
      position: relative;
      left: 5.5px;
      bottom: 4.5px;

      &:hover {
        background-color: rgba(1,1,1,.14);
      }
      &:focus {
        background-color: rgba(1,1,1,.22);
      }
      &:active {
        background-color: rgba(1,1,1,.30);
      }
    }
    &:not(.show-controls)::-webkit-outer-spin-button,
    &:not(.show-controls)::-webkit-inner-spin-button { // Hides arrows for number fields in: Chrome, Safari, Edge, Opera.
      -webkit-appearance: none;
    }
    &:not(.show-controls)[type=number] { // Hides arrows for number fields in Firefox.
      -moz-appearance: textfield;
    }
  }

  &:not(.validate-silently) {
    input:invalid,
    textarea:invalid {
      border-color: #f44336;
    }
  }

  textarea {
    min-height: 70px;
    resize: vertical;
    white-space: pre-wrap;
  }

  button~input {
    padding-right: 36px; // Padding to acommodate for button.
  }

  button {
    all: unset;
    border-radius: var(--border-radius);
    bottom: 6px;
    color: rgba(0, 0, 0, 0.54);
    display: flex; // Removes ghost spacing inside button.
    margin-bottom: -3px;
    margin-right: -3px;
    padding: 2px;
    position: absolute;
    right: 6px;
    user-select: none;
  }
}

.text-field-caption {
  font-size: 0.75rem;
  margin: 5px;
}
