.files {
  display: flex;
  flex-wrap: wrap;
  margin: 5px -3.5px;

  &>* {
    border-radius: var(--border-radius);
    box-sizing: border-box;
    height: 68px;
    margin: 3.5px;
    object-fit: cover;
    width: 68px;
  }
}

.upload-btn {
  align-items: center;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  position: relative;

  &.required {
    color: red;
  }

  input[type=file] {
    left: 33px;
    opacity: 0;
    position: absolute;
    width: 1px;

    &::file-selector-button {
      display:none;
    }
  }
}
