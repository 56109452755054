.widget-issue-timeline {
  padding: 16px;

  > div:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 14px;
  }

  .force-expansion {
    color: rgba(0, 0, 0, 0.38);
    font-size: smaller;
    margin-bottom: 0px;
    padding: 24px;
    text-align: center;
  }

  .profile-pic {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
  }

  .body {
    font-weight: 375;
    line-height: 1.34;
    white-space: pre-line;
  }

  .event {
    font-style: oblique;
  }

  .attachments {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    float: right;
    margin: 5px -2px -2px;
    a {
      display: flex;
      margin: 2px;
    }
    img {
      border-radius: 5px;
    }
  }

  .actor-name-and-timestamp {
    margin-top: 11px;
  }

  .actor-name,
  .timestamp {
    color: rgba(0, 0, 0, 0.625);
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.0333333333em;
    line-height: 1.25rem;
  }
  .timestamp {
    text-align: right;
  }
}
