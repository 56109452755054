.select {
  background-color: #f1f1f1;
  border-bottom: 1px solid rgba(0,0,0,.42);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  padding: 7px 10px 4px;

  .label {
    color: rgba(0,0,0,.6);
    font-size: 12px;
    letter-spacing: -0.35px;
    user-select: none;
    width: 100%;
  }

  .asterisk {
    bottom: 3px;
    color: #f44336;
    font-size: 10px;
    margin-left: 5px;
    position: relative;
  }

  select {
    all: unset;
    appearance: auto;
    background-color: transparent;
    border: none;
    color: rgba(0,0,0,.87);
    font-size: 16px;
    height: 21px;
    margin-top: 4px;
    padding: 0px;

    &[disabled] {
      appearance: none; // Hides dropdown chevron
    }
  }
}
