.page-tenancy {
  --box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 100px;

  > h2 {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 640px;
    padding-left: 14px;
    padding-right: 14px;
  }

  > p {
    box-sizing: border-box;
    font-size: 0.935rem;
    font-weight: 400;
    letter-spacing: 0.0178571429em;
    line-height: 1.15rem;
    margin: 11px auto 14px;
    max-width: 640px;
    padding: 0px 23px;
    text-align: center;
  }

  .tenancy-creator {
    box-sizing: border-box;
    padding: 14px;
    margin: auto;
    max-width: 100%;
    width: 640px;
    > p {
      color: rgba(0, 0, 0, 0.625);
      font-size: 0.935rem;
      font-weight: 400;
      letter-spacing: 0.0178571429em;
      line-height: 1.15rem;
      text-align: center;
    }
  }

  .action-bar-management {
    box-sizing: border-box;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 11px;
    margin-bottom: -11px;
    max-width: 100%;
    overflow: auto;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 11px;
    width: fit-content;
    >:not(:last-child) {
      margin-right: 7px;
    }
  }

  .container-widgets {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-top: 11px;
    max-width: 640px;
    padding-left: 14px;
    padding-right: 14px;
    .widget {
      box-sizing: border-box;
      background-color: white;
      border-radius: 10px;
      box-shadow: var(--box-shadow);
      margin-bottom: 14px;
      padding: 14px;
      width: 100%;
      p {
        font-size: 0.92rem;
        font-weight: 400;
        letter-spacing: 0.0178571429em;
        line-height: 1.25rem;
      }
    }
  }

  .rent-and-balance {
    box-sizing: border-box;
    display: flex;
    margin: 6px auto -11px;
    max-width: 640px;
    overflow: auto;
    padding: 11px 14px; // Needs padding for box shadow
    width: 100%;
    >:not(:first-child) { margin-left: 7px; }
  }

  .widget-statement {
    margin-left: auto;
    margin-right: auto;
    max-width: 612px;
    width: calc(100% - 28px);
  }

  .container-tenant-cards {
    box-sizing: border-box;
    display: flex;
    margin: -11px auto;
    max-width: 100%;
    overflow: auto;
    padding: 11px 14px;
    width: fit-content;
    >:not(:first-child) {
      margin-left: 7px;
    }
  }
  .card-tenant {
    background-color: white;
    border-radius: var(--border-radius-extra);
    box-shadow: var(--box-shadow);
    width: 172px;
    img {
      border-top-left-radius: var(--border-radius-extra);
      border-top-right-radius: var(--border-radius-extra);
    }
    .body {
      padding: 7px 9px;
    }
    .info {
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
    .name {
      font-weight: 500;
    }
  }
}
