.input-phone {
  background-color: #f1f1f1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 0px;

  input {
    all: unset;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    padding: 2px 10px 5px;
    &:invalid {
      border-color: #f44336;
    }
    &:read-write:focus {
      border-bottom-width: 2px;
      margin-bottom: -1px; // Prevents items below from being nudged down upon border width increase.
    }
  }
}

.input-label {
  --label-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: -0.35px;
  padding: 8px 10px 2px;
  box-sizing: border-box;
  height: var(--label-height);
  min-height: var(--label-height);
  max-height: var(--label-height);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;

  .required-indicator::before {
    bottom: 3px;
    color: #f44336;
    content: "✱";
    font-size: 10px;
    margin-left: 5px;
    position: relative;
  }
}
