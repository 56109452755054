.widget-form {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  padding: 14px;

  > p {
    font-size: 0.92rem;
    font-weight: 400;
    letter-spacing: 0.0178571429em;
    line-height: 1.25rem;
    &:first-child { margin-top: 0px; }
  }
}
