.spinner {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-top:    2px solid rgba(0, 0, 0, 0.625);
  border-right:  2px solid rgba(0, 0, 0, 0.625);
  border-bottom: 2px solid rgba(0, 0, 0, 0.625);
  border-left:   2px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  animation: spin 1.1s infinite linear;
  @keyframes spin {
    from { transform: rotate(0deg);   }
    to   { transform: rotate(360deg); }
  }
}
