.page-building {
  --box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 100px;

  header {
    background-color: #1E2430;
    h1 {
      box-sizing: border-box;
      color: white;
      margin: auto;
      max-width: 640px;
      padding: 4px 14px 9px;
    }
    small {
      color: rgba(255, 255, 255, 0.618);
    }
  }

  .action-bar {
    box-sizing: border-box;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 11px;
    margin-bottom: -11px;
    max-width: 100%;
    overflow: auto;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 11px;
    width: fit-content;
    >:not(:last-child) {
      margin-right: 7px;
    }
  }

  .container-widgets {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-top: 11px;
    max-width: 640px;
    padding-left: 14px;
    padding-right: 14px;

    .widget {
      box-sizing: border-box;
      background-color: white;
      border-radius: 9px;
      box-shadow: var(--box-shadow);
      margin-bottom: 14px;
      padding: 14px;
      width: 100%;
      p {
        font-size: 0.92rem;
        font-weight: 400;
        letter-spacing: 0.0178571429em;
        line-height: 1.25rem;
      }
      .street-view {
        border-radius: var(--border-radius);
        width: 100%;
      }
    }
  }

  h2 {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 640px;
    padding-left: 14px;
    padding-right: 14px;
  }

  .container-member-cards {
    box-sizing: border-box;
    display: flex;
    margin: -11px auto;
    max-width: 100%;
    padding: 7.5px 11.5px;
    overflow: auto;
    width: fit-content;
    > div {
      margin: 3.5px;
    }
  }

  .container-card-units {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -3.5px;
    margin-bottom: -3.5px;
    padding-left: 11.5px;
    padding-right: 11.5px;

    .card-unit {
      background-color: white;
      border-radius: 9px;
      box-shadow: var(--box-shadow);
      margin: 3.5px;
      padding: 5.5px;
      width: 100px;
      p {
        margin: 8.5px 2px 0px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }
  }

  .msg-managing-tenancies {
    font-size: 0.935rem;
    letter-spacing: 0.0179em;
    padding-left: 14px;
    padding-right: 14px;
    text-align: center;
  }
}
