.page-tenant-dashboard {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  padding: 16px 16px 100px;
  width: 100%;

  > h1 {
    font-size: x-large;
    .unit-designator {
      background-color: rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      margin-left: 8px;
      padding: 0px 7px;
    }
  }

  > h2 {
    margin-bottom: 12px;
  }

  .top-toolbar {
    display: flex;
    > * {
      flex: 1;
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
    .btn-icon-over-text {
      min-width: unset;
    }
  }

  .widget-form {
    box-sizing: border-box;
    margin-top: 8px;
    width: 100%;
  }

  .rent-and-balance {
    display: flex;
    .widget-balance {
      margin-left: 8px;
    }
  }
  .widget-statement {
    margin-top: 12px;
    width: 100%;
  }

  .issues-toolbar {
    display: flex;
    margin-top: 22px;
    > * {
      &:first-child {
        flex: 5;
      }
      &:last-child {
        flex: 7;
        margin-left: 8px;
      }
    }
  }

  .widget-issue {
    margin-top: 12px;
    width: 100%;
  }

  h2.contact-us {
    color: rgba(0, 0, 0, 0.76);
    font-size: large;
    margin-top: 100px;
    text-align: center;
  }
  p.contact-us {
    color: rgba(0, 0, 0, 0.625);
    line-height: 1.4535;
    text-align: center;
  }
}
