.widget-issue {
  background-color: rgba(255, 255, 255, 0.625);
  border-radius: 10px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  .cover-photo {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 180px;
    object-fit: cover;
    width: 100%;
  }

  .assignee-pic {
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    right: 12px;
    top: 154px;
  }

  .heading {
    color: white;
    padding: 12px;
  }
  .title {
    font-size: 17px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  }
  .tags {
    font-size: 13px;
    margin-top: 2px;
  }

  .location {
    color: rgba(0, 0, 0, 0.625);
    font-size: large;
    font-style: normal;
    font-weight: 400;
    padding: 12px 16px;
    text-transform: uppercase;

    .unit-designator {
      background-color: rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      margin-left: 8px;
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}
