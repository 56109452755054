.info-box {
  --border-color-warning: #dea400; /* 14% darker #ffbd03 from https://designerly.com/best-button-colors-for-websites */
  --border-color-info: #32b5d2; /* 14% darker #55c2da From https://designerly.com/best-button-colors-for-websites */

  color: var(--color-text);
  display: flex;
  font-size: 15px;
}

.info-box i {
  color: white;
  background-color: var(--border-color-info);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
}
.info-box.warning i {
  background-color: var(--border-color-warning);
}

.info-box > div {
  border-color: var(--border-color-info);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-style: solid;
  border-width: 1px 1px 1px 0px;
  line-height: 1.27;
  padding: 12px;
}
.info-box.warning > div {
  border-color: var(--border-color-warning);
}

.info-box .title {
  font-weight: 650;
  margin-bottom: 6px;
}
.info-box button {
  float: right;
  margin-top: 6px;
}
